import React from "react";
import Head from "../components/head.js";

import { Link } from "gatsby";

import Layout from "../components/layout";

const Artists = () => {
  return (
    <Layout>
      <Head title="Artists" />
      <p style={{ marginBottom: "3px" }}>
        <em>
          If you would like to be listed on artnow.world, check out the contact
          page
        </em>
      </p>
      <p>
        <em>Accepting artists of all kinds / types / media</em>
      </p>
      <ul className="artistList">
        {/* <li>
          <Link to="/artist/7orlov">7orlov</Link>
        </li> */}
        <li>
          <Link to="/artist/alisha-chinai">Alisha Chinai</Link>
        </li>
        <li>
          <Link to="/artist/alvaro-sanchez">Alvaro Sánchez</Link>
        </li>
        <li>
          <Link to="/artist/archie-marshall">Archie Marshall</Link>
        </li>
        <li>
          <Link to="/artist/ana-gilbert">Ana Gilbert</Link>
        </li>
        {/* <li>
          <Link to="/artist/anselm-kiefer">Anselm Kiefer</Link>
        </li> */}
        <li>
          <Link to="/artist/az-splice">AZ aka Splice Poet LKL</Link>
        </li>
        {/* <li>
          <Link to="/artist/azarikh">Azarikh</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/belcalis-almanzar">Belcalis Almánzar</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/thebenortlip">Benjamin Horselips</Link>
        </li> */}
        <li>
          <Link to="/artist/benji-friedman">Benji Friedman</Link>
        </li>
        <li>
          <Link to="/artist/bernard-joubert">Bernard Joubert</Link>
        </li>
        <li>
          <Link to="/artist/bon-iver">Bon Iver</Link>
        </li>
        {/* <li>
          <Link to="/artist/boscher-theodor">Boscher Theodor</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/black-market-karma">Black Market Karma</Link>
        </li> */}

        {/* <li>
          <Link to="/artist/borderlinegrafix">borderlinegrafix</Link>
        </li> */}
        <li>
          <Link to="/artist/chan-somethingstar">Chan Somethingstar</Link>
        </li>
        {/* <li>
          <Link to="/artist/corine-ko">Corine Ko</Link>
        </li> */}
        <li>
          <Link to="/artist/craig-david">Craig David</Link>
        </li>
        <li>
          <Link to="/artist/danez-smith">Danez Smith</Link>
        </li>
        {/* <li>
          <Link to="/artist/david-ohlerking">David Ohlerking</Link>
        </li> */}
        <li>
          <Link to="/artist/dean-cobin">Dean Cobin</Link>
        </li>
        {/* <li>
          <Link to="/artist/dispel-zine">Dispel [Zine]</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/ed-buziak">Ed Buziak</Link>
        </li> */}
        <li>
          <Link to="/artist/eversame">Eversame</Link>
        </li>
        {/* <li>
          <Link to="/artist/fred-again">Fred again...</Link>
        </li> */}
        <li>
          <Link to="/artist/frederick-fullerton">Frederick Fullerton</Link>
        </li>
        {/* <li>
          <Link to="/artist/georgia-okeefe">Georgia O'Keefe</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/george-motz">George Motz</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/goran">Goran</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/homare-ikeda">Homare Ikeda</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/ivica-capan">Ivica Capan</Link>
        </li> */}
        <li>
          <Link to="/artist/james-blake">James Blake</Link>
        </li>
        <li>
          <Link to="/artist/jes-goodwin">Jes Goodwin</Link>
        </li>
        {/* <li>
          <Link to="/artist/joan-mitchell">Joan Mitchell</Link>
        </li> */}
        <li>
          <Link to="/artist/joel-rivera">Joel Rivera</Link>
        </li>
        {/* <li>
          <Link to="/artist/john-howard">John Howard</Link>
        </li> */}
        <li>
          <Link to="/artist/julia-butterfly-hill">Julia Butterfly Hill</Link>
        </li>
        {/* <li>
          <Link to="/artist/julia-vinograd">Julia Vinograd</Link>
        </li> */}
        <li>
          <Link to="/artist/juliano_so_cute_lowkey">Juliano Hodges</Link>
        </li>
        <li>
          <Link to="/artist/june-klein">June Klein</Link>
        </li>
        <li>
          <Link to="/artist/kenny-deforest">Kenny DeForest</Link>
        </li>
        <li>
          <Link to="/artist/lanny-quarles">Lanny Quarles</Link>
        </li>
        {/* <li>
          <Link to="/artist/lou-patrou">Lou Patrou</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/marcus-hammerschmitt">Marcus Hammerschmitt</Link>
        </li> */}
        <li>
          <Link to="/artist/mari-k">Mari K</Link>
        </li>
        <li>
          <Link to="/artist/nathaniel-dorsky">Nathaniel Dorsky</Link>
        </li>
        {/* <li>
          <Link to="/artist/nei-caetano-da-silva">Nei Caetano da Silva</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/nobuyuki-satow">Noboyuki Satow</Link>
        </li> */}
        <li>
          <Link to="/artist/ocean-vuong">Ocean Vuong</Link>
        </li>
        {/* <li>
          <Link to="/artist/parteum">Parteum</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/pedro-sequira">Pedro Sequeira</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/peter-marek">Peter Marek</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/romain-joveneau">Romain Joveneau</Link>
        </li> */}
        <li>
          <Link to="/artist/rosalia">Rosalía</Link>
        </li>
        <li>
          <Link to="/artist/scott-hronich">Scott Hronich</Link>
        </li>
        <li>
          <Link to="/artist/talon-abraxas">Talon Abraxas</Link>
        </li>
        {/* <li>
          <Link to="/artist/teitur-magnusson">Teitur Magnússon</Link>
        </li> */}
        {/* <li>
          <Link to="/artist/tony-van-den-boomen">Tony Van Den Boomen</Link>
        </li> */}
        <li>
          <Link to="/artist/travis-dickinson">Travis Dickinson</Link>
        </li>
        <li>
          <Link to="/artist/yun-fei-ji">Yun-Fei Ji</Link>
        </li>
      </ul>
      {/* <p>-----</p>
      <p>Connect with artnow.world on Social Media as well!</p>
      <h3>
        <a href="https://facebook.com/artnow.world">Facebook</a>
      </h3>
      <h3>
        <a href="https://instagram.com/artnow.world">Instagram</a>
      </h3> */}
    </Layout>
  );
};

export default Artists;
